import { css } from 'lit-element'
// language=CSS
export const styles = css`
  :host {
    display: flex;
    justify-content: space-around;
  }

  .configurator {
    width: 100%;
  }
  @media (max-width: 767px) {
    .configurator {
      /* overflow: hidden; */
    }
  }
  .group-options {
    margin-bottom: var(--spacing-lg);
  }
  .group-options + .separator {
    height: 2px;
    margin-bottom: var(--spacing-md);
    background: var(--base-separator);
    opacity: 0.5;
  }
  .group-options,
  .bundle-options {
    display: flex;
    flex-direction: column;
    margin-right: -6px;
    margin-left: -6px;
  }
  :host([show-labels]) .group-options > div,
  :host([show-labels]) .bundle-options > div,
  :host([show-labels]) .global-attributes > dl > div {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: solid 1px #ddd;
  }

  /* :host(:not([show-labels])) .group-options > div > label { */

  /* display: none; */

  /* } */

  .group-options > *,
  .bundle-options > * {
    box-sizing: border-box;
    width: 100%;
    padding-right: 6px;
    padding-left: 6px;
  }
  @media (max-width: 767px) {
    .group-options,
    .bundle-options {
      margin-right: -4px;
      margin-left: -4px;
    }
    .group-options > *,
    .bundle-options > * {
      padding-right: 4px;
      padding-left: 4px;
    }
  }
  .bundle-options li {
    margin-bottom: 3px;
    padding-bottom: 3px;
    border-bottom: solid 1px #b3b2b2;
  }
  select {
    border: solid 1px #ddd;
  }

  .group-options > div:not(:first-of-type) .group-label {
    display: block;
    margin-top: calc(var(--spacing-sm) * 1.23);
  }
  @media (max-width: 767px) {
    .group-options > div:not(:first-of-type) .group-label {
      margin-top: calc(var(--spacing-sm) * 3);
    }
  }
  .group-label strong {
    font-weight: 500;
    font-size: 20px;
    font-family: var(--font-family-alt);
    line-height: 20px;
    letter-spacing: -0.33px;
  }

  .group-label::after {
    display: block;
    height: 2px;
    margin-top: calc(var(--spacing-sm) * 0.461);
    margin-bottom: calc(var(--spacing-sm) * 1.2);
    background: var(--base-separator);
    opacity: 0.5;
    content: '';
  }
  @media (max-width: 767px) {
    .group-label::after {
      margin-bottom: calc(var(--spacing-md) * 1);
    }
  }

  .input-outer-wrapper:not(:last-of-type) {
    margin-bottom: calc(var(--spacing-sm) * 1.23);
  }
  @media (max-width: 767px) {
    .input-outer-wrapper:not(:last-of-type) {
      margin-bottom: calc(var(--spacing-sm) * 2.15);
    }
  }
  .input-outer-wrapper .label-wrapper > span {
    font-weight: 500;
    font-size: 16px;
    font-family: var(--font-family-alt);
    line-height: 1.5em;
    letter-spacing: -0.015625em;
  }
  .input-outer-wrapper .label-wrapper > span:not(.value) {
    flex: 1 1 auto;
  }
  .input-outer-wrapper .label-wrapper > .value {
    color: var(--medium-grey);
    font-weight: 400;
    font-size: 15px;
  }

  .input-outer-wrapper .label-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: calc(var(--spacing-sm) * 0.193);
  }
  .input-outer-wrapper.swatch .label-wrapper {
    margin-bottom: calc(var(--spacing-sm) * 0.5);
  }
  @media (max-width: 767px) {
    .input-outer-wrapper .label-wrapper {
      margin-bottom: calc(var(--spacing-sm) * 0.6);
    }
  }

  .disable-group {
    margin-top: calc(var(--spacing-sm) * -0.4);
    margin-bottom: var(--spacing-xs);
  }
  .disable-group label {
    display: flex;
    align-items: center;
    color: var(--medium-grey);
    font-weight: normal;
    font-size: 15px;
  }
  .disable-group label checkbox-input {
    margin-right: 8px;
  }

  .aggregated-delivery {
    position: relative;
    margin: var(--spacing-xs) 0;
    text-align: center;
  }
  .aggregated-delivery::before {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 1px;
    background: black;
    transform: translateY(-50%);
    opacity: 0.1;
    content: '';
  }
  .aggregated-delivery .date {
    display: inline-block;
    margin: 7px;
    padding: 0 7px;
    color: var(--success);
    font-weight: 500;
    font-size: calc(var(--font-size-base) * 0.85);
    background: white;
    border: solid 2px;
    border-radius: 2px;
    outline: solid 7px white;
  }
  .aggregated-delivery .date.delayed {
    /* color: var(--base-contrast-muted) */
  }

  [slot='prefix'] {
    margin-right: 7px;
    margin-left: 7px;
    color: var(--base-contrast);
    font-weight: 500;
    font-size: calc(var(--font-size-base) - 2px);
    font-family: var(--font-family-base);
  }

  @media (max-width: 410px) {
    [slot='prefix'] {
      font-size: 12px;
    }
  }
  @media (max-width: 359px) {
    [slot='prefix'] {
      margin-right: 0;
      margin-left: 0;
      font-size: 11px;
    }
  }

  .group-toggle-wrapper {
    text-align: left;
  }

  .group-summary {
    text-align: left;
  }

  /* .btn-bundle-cart { */

  /* background: var(--primary, darkblue); */

  /* color: var(--primary-contrast, white); */

  /* text-align: center; */

  /* border-radius: 50px; */

  /* display: flex; */

  /* align-items: center; */

  /* !*justify-content: space-between;*! */

  /* justify-content: center; */

  /* text-transform: none; */

  /* box-shadow: -1px 15px 11px -5px rgba(0,0,0,0.2); */

  /* } */
  paper-button {
    background: hotpink;
    transition: none;
  }
  paper-button[pressed] {
    box-shadow: -1px 6px 3px -4px rgba(0, 0, 0, 0.4);
    transform: translateY(4px);
  }
  paper-button[disabled] {
    box-shadow: none;
    opacity: 0.5;
  }
  .btn-bundle-cart .label {
    font-weight: 500;
    font-size: 17px;
  }
  .btn-bundle-cart .prices {
    display: flex;
    align-items: center;
  }
  .btn-bundle-cart .final-price {
    margin-left: 10px;
    padding: 0 8px;
    font-weight: 600;
    border: solid 2px;
    border-radius: 50px;
  }

  add-to-cart-bar .prices {
    display: flex;
    align-items: baseline;
  }
  add-to-cart-bar .prices .old-price s {
    margin-right: 10px;
    font-size: 85%;
  }
  add-to-cart-bar[mobile] .prices .old-price s {
    margin-right: 5px;
  }
  add-to-cart-bar[mobile] .prices .old-price {
    order: 1;
  }
  add-to-cart-bar .prices .final-price {
    color: var(--secondary);
    font-weight: bold;
    font-size: var(--medium__font-size);
    font-family: var(--font-family-alt);
    letter-spacing: -0.0165em;
  }
  add-to-cart-bar .prices .final-price .tax {
    margin-left: calc(var(--spacing-xs) * 0.4);
    color: var(--medium-grey);
    font-weight: normal;
    font-size: var(--micro__font-size);
    font-family: var(--font-family-base);
    letter-spacing: -0.1px;
  }

  .dropdown {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;
  }
  .dropdown > * {
    width: 100%;
  }
  .swatch {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;
  }
  swatch-options swatch-option {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.2) 0 0 0 1px;
  }
  swatch-options swatch-option[selected] {
    box-shadow: rgb(255, 255, 255) 0 0 0 5px, rgba(0, 0, 0, 0.25) 0 0 0 6px;
  }
  swatch-options.image swatch-option {
    width: 40px;
    height: 40px;
    border-radius: 3px;
  }
  swatch-options swatch-option iron-icon {
    width: 34px;
    height: 34px;
    color: white;
    filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0.5));
  }
  swatch-options swatch-option iron-icon.light {
    color: var(--base-contrast);
  }
  @media (max-width: 767px) {
    select-option[hidden]:first-of-type + select-option,
    visual-select-option[hidden]:first-of-type + visual-select-option {
      border-top-left-radius: var(--select-option__border-radius, var(--__border-radius));
      border-top-right-radius: var(--select-option__border-radius, var(--__border-radius));
    }
  }

  select-option {
    justify-content: space-between;
  }
  select-option .option-price {
    color: var(--base-contrast-muted);
    white-space: nowrap;
    opacity: 0.7;
  }

  .validation-failed:not(:focus)::before {
    border: solid 2px var(--error);
    border-radius: inherit;
  }

  .dropdown select {
    font-size: 15px;
  }
  :host(:not([loaded])) {
    /* opacity: 0; */
  }

  /* ----------- Edit mode ---------- */
  .editor-buttons {
    margin-bottom: 15px;
  }

  /* Login */
  .login-form {
    flex: 1;
  }
  .login-form label span {
    display: inline-block;
    min-width: 95px;
  }
  .login-form shop-button {
    display: inline-block;
    margin-top: 15px;
  }
  .login-error {
    max-width: 400px;
    color: var(--error);
    font-size: 85%;
  }

  /* Show Labels */

  /* :host([show-labels]) { */

  /* !*width: 1700px;*! */

  /* !*margin-left: -900px;*! */

  /* position: relative; */

  /* z-index: 5; */

  /* background: white; */

  /* min-height: 14000px; */

  /* } */

  /* :host([show-labels]) .configurator { */

  /* !*width: 500px;*! */

  /* } */

  /* :host(:not([show-labels])) .debug-data { */

  /* display: none; */

  /* } */
  :host .debug-data {
    position: fixed;
    top: 0;
    left: 0;
    box-sizing: border-box;

    /* vp-style: 740px-1440px @ 1200px-2560px */
    width: calc(122.4px + 51.47vw);
    height: 100vh;
    padding: 220px 50px;
    overflow: auto;
    background: white;
    transform: none;
  }
  :host .debug-data li {
    font-size: 9.5px;
  }

  .debug-data h3 {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .debug-data ul {
    display: inline-flex;
    flex-direction: column;
    align-items: baseline;
    margin-top: 0;
    margin-bottom: 10px;
    padding-left: 0;
    list-style-type: none;
  }
  .debug-data ul li {
    margin-top: 0.3em;
    margin-bottom: 0.3em;
    padding: 0 2px;
    line-height: 1.3;
  }
  .debug-data ul li[highlighted] {
    outline: 1px dotted #212121;
    outline: 5px auto -webkit-focus-ring-color;
  }

  /* Price editor */
  :host([edit-mode]) {
    font-size: 12.5px;
    line-height: 2;
  }
  :host([edit-mode]) .dropdown {
    flex-wrap: wrap;
  }
  :host([edit-mode]) .dropdown fieldset {
    flex: 1 1 100%;
    box-sizing: border-box;
    margin-bottom: 5px;
    background: white;
  }

  :host([edit-mode]) .dropdown fieldset div.inputs-wrapper {
    display: flex;
    flex-direction: column;
  }
  :host([edit-mode]) .dropdown fieldset div.input-wrapper {
    display: flex;
    justify-content: space-between;
  }
  :host([edit-mode]) .price-wrapper input {
    width: 72px;
  }
  :host([edit-mode]) .group-label .price-wrapper input {
    width: 135px;
  }

  :host([edit-mode][show-price-editor]) .group-options > div,
  :host([edit-mode][show-price-editor]) .bundle-options > div,
  :host([edit-mode][show-price-editor]) .global-attributes > dl > div {
    margin-bottom: 30px;
    padding: 15px;
    background: #eee;
  }
  :host([edit-mode][show-price-editor]) .group-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 17px;
  }
  :host([edit-mode][show-price-editor]) .group-label.base-price,
  :host([edit-mode][show-price-editor]) .group-label.base-price strong {
    display: block;
  }
  :host([edit-mode][show-price-editor]) .group-label strong,
  :host([edit-mode][show-price-editor]) .group-label.base-price .price-wrapper {
    margin-left: -15px;
  }
  :host([edit-mode][show-price-editor]) .configurator {
    /* width: 700px; */
  }

  select {
    /*   clear: left; */

    /*   float: left; */
    box-sizing: border-box;

    /* width: 100%; */
    padding: 4px 30px 6px 14px;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMjg0LjkyOXB4IiBoZWlnaHQ9IjI4NC45MjlweCIgdmlld0JveD0iMCAwIDI4NC45MjkgMjg0LjkyOSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjg0LjkyOSAyODQuOTI5OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PHBhdGggZD0iTTI4Mi4wODIsNzYuNTExbC0xNC4yNzQtMTQuMjczYy0xLjkwMi0xLjkwNi00LjA5My0yLjg1Ni02LjU3LTIuODU2Yy0yLjQ3MSwwLTQuNjYxLDAuOTUtNi41NjMsMi44NTZMMTQyLjQ2NiwxNzQuNDQxTDMwLjI2Miw2Mi4yNDFjLTEuOTAzLTEuOTA2LTQuMDkzLTIuODU2LTYuNTY3LTIuODU2Yy0yLjQ3NSwwLTQuNjY1LDAuOTUtNi41NjcsMi44NTZMMi44NTYsNzYuNTE1QzAuOTUsNzguNDE3LDAsODAuNjA3LDAsODMuMDgyYzAsMi40NzMsMC45NTMsNC42NjMsMi44NTYsNi41NjVsMTMzLjA0MywxMzMuMDQ2YzEuOTAyLDEuOTAzLDQuMDkzLDIuODU0LDYuNTY3LDIuODU0czQuNjYxLTAuOTUxLDYuNTYyLTIuODU0TDI4Mi4wODIsODkuNjQ3YzEuOTAyLTEuOTAzLDIuODQ3LTQuMDkzLDIuODQ3LTYuNTY1QzI4NC45MjksODAuNjA3LDI4My45ODQsNzguNDE3LDI4Mi4wODIsNzYuNTExeiIvPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48L3N2Zz4=)
      calc(100% - 10px) center / 12px no-repeat #eee;
    border: none;

    /* font-size: 15px; */
    border-radius: 5px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  /* CAUTION: IE hackery ahead */
  select::-ms-expand {
    display: none; /* remove default arrow on ie10 and ie11 */
  }

  .input-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .modal-button-wrapper {
    /* min-width: 19px; */
    min-height: 35px;
    transform: translateY(-2px);
  }
  .modal-button-wrapper:not(:empty) {
    margin-left: 5px;
  }
  .modal-button-wrapper > span {
    cursor: pointer;
  }
  modal-dialog {
    --modal-dialog-max-width: 715px;

    /* --modal-dialog-close-top: 0; */

    /* --modal-dialog-close-right: 0; */
  }
  modal-dialog [slot='close'] {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 32px;

    /* background: black; */
    height: 32px;
    color: var(--base-contrast);

    /* padding-bottom: 4px; */
  }

  modal-dialog ul {
    padding: 0;
    list-style: none;
  }

  modal-dialog li {
    position: relative;
    padding-left: 1.2em;
    line-height: 1.5625em;
  }

  modal-dialog li::before {
    position: absolute;
    left: 0;
    padding-right: 0.4em;
    color: var(--primary);
    content: '• ';
  }

  modal-dialog h1 {
    color: var(--h1__color);
    font-weight: var(--h1__font-weight);
    font-size: var(--h1__font-size);
    font-family: var(--h1__font-family);
    line-height: var(--h1__line-height);
  }

  modal-dialog h2 {
    margin-top: var(--h2__margin-top);
    margin-bottom: var(--h2__margin-bottom);
    color: var(--h2__color);
    font-weight: var(--h2__font-weight);
    font-size: var(--h2__font-size);
    font-family: var(--h2__font-family);
    line-height: var(--h2__line-height);
  }

  modal-dialog h3 {
    margin-top: var(--h3__margin-top);
    margin-bottom: var(--h3__margin-bottom);
    color: var(--h3__color);
    font-weight: var(--h3__font-weight);
    font-size: var(--h3__font-size);
    font-family: var(--h3__font-family);
    line-height: var(--h3__line-height);
  }

  modal-dialog h4 {
    margin-top: var(--h4__margin-top);
    margin-bottom: var(--h4__margin-bottom);
    color: var(--h4__color);
    font-weight: var(--h4__font-weight);
    font-size: var(--h4__font-size);
    font-family: var(--h4__font-family);
    line-height: var(--h4__line-height);
  }

  modal-dialog h5 {
    margin-top: var(--h5__margin-top);
    margin-bottom: var(--h5__margin-bottom);
    font-weight: var(--h5__font-weight);
    font-size: var(--h5__font-size);
    font-family: var(--h5__font-family);
    line-height: var(--h5__line-height);
  }

  modal-dialog h6 {
    margin-top: var(--h6__margin-top);
    margin-bottom: var(--h6__margin-bottom);
    font-weight: var(--h6__font-weight);
    font-size: var(--h6__font-size);
    font-family: var(--h6__font-family);
    line-height: var(--h6__line-height);
  }

  modal-dialog a {
    color: var(--base-contrast-alt);
    text-decoration: none;
  }

  modal-dialog p {
    margin-top: 0;
    margin-bottom: var(--spacing-xs);
  }

  modal-dialog p + ul,
  modal-dialog p + ol {
    margin-top: -0.8rem;
  }

  modal-dialog strong + p,
  modal-dialog strong + ul,
  modal-dialog strong + ol {
    margin-top: 5px;
  }

  modal-dialog p + h1,
  modal-dialog p + h2,
  modal-dialog p + h3,
  modal-dialog p + h4 {
    margin-top: var(--spacing-sm);
  }

  @media (max-width: 767px) {
    p {
      margin-bottom: var(--spacing-md);
    }
  }

  .preview-table > tr > td {
    border-bottom: solid 1px #ddd;
  }
  .preview-table td.group-name {
    font-weight: bold;
    vertical-align: bottom;
  }
  .preview-table {
    width: 100%;
    border-spacing: 0;
  }
  .preview-modal {
    --modal-dialog-max-width: 950px;
  }
`
